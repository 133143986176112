import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"

import Tags from "src/components/ProductionTags"
import VideoPlayer from "src/components/VideoPlayer"
import { LAYOUT } from "src/constants"
import { richText } from "src/helpers/renderRichText"
import Layout from "src/layout"

const Content = styled.div`
  text-align: center;
  max-width: 1200px;
  margin: auto;
  padding: 0 20px;
`
const Description = styled.div`
  text-align: left;
  padding-top: 20px;
`
const Image = styled(GatsbyImage)`
  height: calc(100vh - ${LAYOUT.top * 2}px);
`

const ProductionTemplate = ({ data, pageContext: { slug } }) => {
  const production = data.contentfulProduction
  const isImg = production.media.file.contentType.indexOf("image") !== -1
  const image = getImage(production.media)

  return (
    <Layout>
      <Content>
        {isImg ? (
          <Image image={image} alt={production.title} imgStyle={{ objectFit: "contain" }} />
        ) : (
          <VideoPlayer production={production} height={600} />
        )}
        <Tags node={production} visible />
        {production.description && <Description>{richText(production.description)}</Description>}
      </Content>
    </Layout>
  )
}

export default ProductionTemplate

export const pageQuery = graphql`
  query ProductionById($id: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    contentfulProduction(id: { eq: $id }) {
      id
      title
      youtube
      description {
        raw
      }
      links {
        ... on ContentfulAuthor {
          id
          name
        }
        ... on ContentfulTag {
          id
          name
        }
        ... on ContentfulCreation {
          id
          title
        }
      }
      media {
        file {
          url
          fileName
          contentType
          details {
            image {
              width
              height
            }
          }
        }
        gatsbyImageData(width: 1200, placeholder: BLURRED)
      }
    }
  }
`
